<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Request</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <request-grid ref="grid" :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" :statusClick="statusClick" :pdfClick="pdfClick"/>
                <request-status-form ref="RequestStatusForm" :reload="reload" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction';
import requestGrid from '../Request/Grid/RequestGrid.vue';
import RequestServices from './Script/RequestServices';
// import purchasingPOServices from './Script/PurchasingPOServices'
import RequestStatusForm from './Component/RequestStatusForm.vue'
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'PurchasingPurchaseOrder',
    components: {
        'request-grid': requestGrid,
        'request-status-form': RequestStatusForm,
    },
    async mounted () {
        this.statusFiltering();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$router.push({ name: 'Request Form', params: {  formtype:'Add', id:' ', view:'new' } });
        },
        editClick(id, view){
            // this.$router.push({ name: 'Request Form', params: { formtype:'Edit', id:id, view:view } });
            const routeData = this.$router.resolve({ name: 'Request Form', params: { formtype:'Edit', id:id, view:view } });
            window.open(routeData.href, '_blank');
        },
        async deleteClick(id){
            var data = await RequestServices.getRequestData(id);
            
            this.$swal(sweet_alert.delete).then((result) => {
                if (result.isConfirmed == true) {
                    
                    const requestData = {
                        request_number: data.request_number,
                        status: 'Delete',
                        request_date: data.request_date,
                        notes: data.notes,
                        printed_notes: data.printed_notes,
                        address: data.address,
                        attachment: null,
                        purchasing_request_detail: this.$globalfunc.objectToArrayConverter(data.purchasing_request_detail, "Request-ItemGridDataRequest")
                    }
                    
                    const variables = {
                        id : parseInt(data.request_id),
                        data : requestData
                    }

                    RequestServices.editQuery(variables).then(res => {
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");                 
                    }).catch(error => {
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },        
        statusClick(data) {
            this.$refs.RequestStatusForm.statusClick(data);
        },
        pdfClick(id) {
            // this.$refs.pdf.generatePDF(id);
        },
        reload(){
            this.gridReload++;
            this.statusFiltering();
        },
        async statusFiltering() {
            // var countStatus = await globalfunc.countTotal(window.location.pathname);
            // this.statusButton = await this.$globalfunc.dynamicStatusButton('ddl_status_purchasing_request', countStatus);
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnRevisi").on('click', function() {
                    vue.$refs.grid.changeStatus('Revisi');
                });
                $("#btnChecking").on('click', function() {
                    vue.$refs.grid.changeStatus('Checking');
                });
                $("#btnApproved").on('click', function() {
                    vue.$refs.grid.changeStatus('Approved');
                });
                $("#btnReady").on('click', function() {
                    vue.$refs.grid.changeStatus('Ready');
                });
                $("#btnPending").on('click', function() {
                    vue.$refs.grid.changeStatus('Pending');
                });
                $("#btnDelete").on('click', function() {
                    vue.$refs.grid.changeStatus('Delete');
                });
                $("#btnFinish").on('click', function() {
                    vue.$refs.grid.changeStatus('Finish');
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
            })
        },
    }
}
</script>

<style scoped>
</style>