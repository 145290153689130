import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class RequestService {
    readRequestQuery(){
        var query = `query($paging:ServerPaging, $status:String){
            GetPurchasingRequestV(Paging:$paging, Status:$status){
                purchasing_request {
                    request_id
                    status
                    request_number
                    request_date
                    address
                    created_at
                    notes
                    printed_notes
                    attachment
                    created_by
                    purchasing_request_detail {
                        item_id
                        product_code
                        item_name
                        barcode
                        type_detail_id
                        uom
                        quantity
                    }
                }
                total
            }
        }`;
        return query;
    }

    readItemQuery(){
        var query = `query($paging:ServerPaging){
                        GetProductMaster(Paging:$paging){
                            ProductMaster{
                                item_id
                                product_code
                                item_name
                                barcode
                                active_flag
                                item_type
                                type_detail_id
                                base_uom
                                base_weight
                            }
                            Total
                        }
                    }`;
        return query;
    }

    duplicateItemChecker(oldData, newData){
        var duplicate = 0;
        for (let i = 0; i < oldData.length; i++){
            if(oldData[i].item_id == newData.item_id){
                duplicate++;
            }
        }
        return duplicate==0 ? false : true;
    }

    
    async getRequestData(id){
        const variables = {
            id : id
        }

        var query = gql`
            query ($id:Int) {
                GetPurchasingRequestV (RequestId: $id) {
                    purchasing_request {
                        request_id
                        status
                        request_number
                        request_date
                        address
                        created_at
                        notes
                        printed_notes
                        attachment
                        created_by
                        purchasing_request_detail {
                            item_id
                            product_code
                            item_name
                            barcode
                            type_detail_id
                            uom
                            quantity
                        }
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query,variables);
        return result.data.GetPurchasingRequestV.purchasing_request[0];
    }

    async addQuery(variables){
        var query = gql`mutation($data:NewPurchasingRequest!){
            CreatePurchasingRequest(New:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`mutation($id:Int!,$data:NewPurchasingRequest!){
            UpdatePurchasingRequest(ID:$id,New:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new RequestService();